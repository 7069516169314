@import 'styles/includes';

.FaqList {
    $root: &;

    @extend %container;
    margin-top: 6rem;
    margin-bottom: 6rem;

    @include media(m) {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }

    &__Title {
        padding: 0 0 1.6rem;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 3rem;
        line-height: 100%;
        color: $black;
        border-bottom: 2px solid $black;

        @include media(s) {
            font-size: 6rem;
        }
    }
}
